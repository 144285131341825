import { SolderFeederVideo } from "pages/Product/constants";
import { BathTrolley } from "pages/Product/constants";
import { RPMSensorImg } from "pages/Product/constants";
import { O2analyserVideo } from "pages/Product/constants";
import { NozzleConditioningVideo } from "pages/Product/constants";
import { ReelDetectImg } from "pages/Product/constants";
import { ThermalNozzleCalVideo } from "pages/Product/constants";
import { WaveHeightVideo } from "pages/Product/constants";
import { RingHeater } from "pages/Technology/constants";
import { SynchrodexPreHeatLamps } from "pages/Technology/constants";
import { TwinBathVideo } from "pages/Technology/constants";

const IMAGE_BASE_URL = process.env.REACT_APP_IMAGE_BASE_URL;
const VIDEO_BASE_URL = process.env.REACT_APP_VIDEO_BASE_URL;

export const ROUTES_WITH_BANNER_IMAGES = [
  "medical",
  "orissa fusion 4",
  "synchrodex pro 1600 dual bath solder module",
  "synchrodex pro 1600 single bath solder module",
  "synchrodex pro 1600 pre-heater module",
  "orissa synchrodex pro 1060 pre-heater module",
  "orissa fusion pro 508",
  "orissa synchrodex solder module",
  "orissa synchrodex pre-heat module",
  "synchrodex fluxer module",
];

// Enhancements
const FluxMonitor1 =
  `${IMAGE_BASE_URL}enhancements/fluxmonitor1.jpeg` ||
  "assets/images/enhancements/fluxmonitor1.jpeg";
const FluxMonitor2 =
  `${IMAGE_BASE_URL}enhancements/fluxmonitor2.jpeg` ||
  "assets/images/enhancements/fluxmonitor2.jpeg";
const FluxFlowMeter =
  `${IMAGE_BASE_URL}enhancements/flux_flow_meter.jpg` ||
  "assets/images/enhancements/flux_flow_meter.jpg";

export const BANNER_DETAILS = {
  default: [
    {
      details: {
        Installations: "800+",
        "Worked Hours": "4.5k+",
        "Produced Boards": "2k+",
      },
      title: "Orissa Synchrodex Pro Combo",
      backgroundImg: `${IMAGE_BASE_URL}products/fusion-4.png`,
    },
    {
      details: {
        Installations: "40+",
        Effectiveness: "97%",
        "Market Share": "28%",
      },
      titlePrefix: "Market Sector",
      title: "Electric Vehicles",
      backgroundImg: `${IMAGE_BASE_URL}market-sector/banner-market-sector-img.png`,
    },
    {
      details: {
        Installations: "800+",
        "Worked Hours": "4.5k+",
        "Produced Boards": "2k+",
      },
      title: "Orissa Synchrodex Pro Combo",
      backgroundImg: `${IMAGE_BASE_URL}products/fusion-4.png`,
    },
  ],
  aerospace: [
    {
      details: {
        Installations: "800+",
        "Worked Hours": "4.5k+",
        "Produced Boards": "2k+",
      },
      title: "Orissa Synchrodex Pro Combo",
      backgroundImg: `${IMAGE_BASE_URL}market-sector/Cockpit.jpg`,
    },
    {
      details: {
        Installations: "40+",
        Effectiveness: "97%",
        "Market Share": "28%",
      },
      titlePrefix: "Market Sector",
      title: "Electric Vehicles",
      backgroundImg: `${IMAGE_BASE_URL}market-sector/Top Level Image.jpg`,
    },
  ],
  "automotive/ev": [
    {
      details: {
        Installations: "800+",
        "Worked Hours": "4.5k+",
        "Produced Boards": "2k+",
      },
      title: "Orissa Synchrodex Pro Combo",
      backgroundImg: `${IMAGE_BASE_URL}market-sector/market-banner-1.png`,
    },
    {
      details: {
        Installations: "800+",
        "Worked Hours": "4.5k+",
        "Produced Boards": "2k+",
      },
      title: "Orissa Synchrodex Pro Combo",
      backgroundImg: `${IMAGE_BASE_URL}market-sector/market-banner-2.png`,
    },
    {
      details: {
        Installations: "40+",
        Effectiveness: "97%",
        "Market Share": "28%",
      },
      titlePrefix: "Market Sector",
      title: "Electric Vehicles",
      backgroundImg: `${IMAGE_BASE_URL}market-sector/market-banner-3.png`,
    },
  ],
  medical: [
    {
      backgroundImg: `${IMAGE_BASE_URL}market-sector/medical_banner_1.jpg`,
    },
  ],
  fluxing: [
    {
      backgroundImg: FluxMonitor1,
    },
    {
      backgroundImg: FluxMonitor2,
    },
    {
      backgroundImg: FluxFlowMeter,
    },
  ],
  preheating: [
    {
      backgroundImg: TwinBathVideo,
    },
    {
      backgroundImg: SynchrodexPreHeatLamps,
    },
    {
      backgroundImg: RingHeater,
    },
  ],
  soldering: [
    {
      backgroundImg: TwinBathVideo,
    },
    {
      backgroundImg: WaveHeightVideo,
    },
    {
      backgroundImg: SolderFeederVideo,
    },
    {
      backgroundImg: ReelDetectImg,
    },
    {
      backgroundImg: ThermalNozzleCalVideo,
    },
    {
      backgroundImg: BathTrolley,
    },
    {
      backgroundImg: RPMSensorImg,
    },
    {
      backgroundImg: NozzleConditioningVideo,
    },
    {
      backgroundImg: O2analyserVideo,
    },
  ],
  other: [
    {
      backgroundImg: `${IMAGE_BASE_URL}enhancements/Fiducial.jpg`,
    },
    {
      backgroundImg: `${VIDEO_BASE_URL}enhancements/boardwarp.mp4`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}enhancements/mag pump.jpg`,
    },
    {
      backgroundImg: `${VIDEO_BASE_URL}enhancements/TwinBath.mp4`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}enhancements/AOI-Automatic.png`,
    },
  ],
  "orissa fusion 4": [
    {
      backgroundImg: `${IMAGE_BASE_URL}products/orissa-fusion-4/F4 FHSS ISO.png`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/orissa-fusion-4/F4 Conveyor Outside.jpg`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/orissa-fusion-4/F4 RENDER DOORS DOWN.png`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/orissa-fusion-4/F4 RENDER FRONT VIEW.png`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/orissa-fusion-4/F4 RENDER TOP VIEW.png`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/orissa-fusion-4/F4 (replace - bad colouring, consistent angle, can_t read machine name).png`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/orissa-fusion-4/F4 Top Side Pre-Heat (replace - too dark, not central).jpg`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/orissa-fusion-4/F4 Conveyor (replace - different colour).jpg`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/orissa-fusion-4/F4 Example Configuration (replace - different colour).jpg`,
    },
  ],
  "synchrodex pro 1600 dual bath solder module": [
    {
      backgroundImg: `${IMAGE_BASE_URL}products/sp1600dbath/SP1600 DBATH DOORS DOWN.png`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/sp1600dbath/SP1600 DBATH DOORS UP.png`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/sp1600dbath/SP1600 DBATH ISO 01.07.24.png`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/sp1600dbath/SP1600 DBATH ISO BSPH 01.07.24.png`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/sp1600dbath/SP1600 DBATH RENDER FRONT VIEW.png`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/sp1600dbath/SP1600 DBATH RENDER TOP VIEW.png`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/sp1600dbath/SP1600 DBATH Solder Baths.jpg`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/sp1600dbath/SP1600 DBATH.jpg`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/sp1600dbath/SP1600 DBATH.png`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/sp1600dbath/SP1600 DBATH.png`,
    },
    {
      backgroundImg: `${VIDEO_BASE_URL}products/sp1600dbath/SP ANIMATION.mp4`,
    },
    {
      backgroundImg: `${VIDEO_BASE_URL}products/sp1600dbath/SP VIDEO.mp4`,
    },
  ],
  "synchrodex pro 1600 single bath solder module": [
    {
      backgroundImg: `${IMAGE_BASE_URL}products/sp1600-sbath/SP1600 SBATH ISO.png`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/sp1600-sbath/SP1600 SBATH RENDER FRONT VIEW.png`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/sp1600-sbath/SP1600 SBATH RENDER TOP VIEW.png`,
    },
    {
      backgroundImg: `${VIDEO_BASE_URL}products/sp1600-sbath/SP ANIMATION.mp4`,
    },
    {
      backgroundImg: `${VIDEO_BASE_URL}products/sp1600-sbath/SP VIDEO.mp4`,
    },
  ],
  "synchrodex pro 1600 pre-heater module": [
    {
      backgroundImg: `${IMAGE_BASE_URL}products/sp1600ph/SP1600 PH RENDER DOORS UP.png`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/sp1600ph/SP1600 PH RENDER FRONT VIEW.png`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/sp1600ph/SP1600 PH RENDER ISO DOORS UP.png`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/sp1600ph/SP1600 PH RENDER ISO.png`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/sp1600ph/SP1600 PH RENDER TOP VIEW.png`,
    },
  ],
  "orissa synchrodex pro 1060 pre-heater module": [
    {
      backgroundImg: `${IMAGE_BASE_URL}products/sp1060ph/SP1060_PH_DOORS_DOWN.png`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/sp1060ph/SP1060_PH_ISO.png`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/sp1060ph/SP1060_PH_RENDER_FRONT_VIEW.png`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/sp1060ph/SP1060_PH_RENDER_TOP_VIEW.png`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/sp1060ph/SP1060_PH.png`,
    },
    {
      backgroundImg: `${VIDEO_BASE_URL}products/sp1060ph/SP%20ANIMATION.mp4`,
    },
    {
      backgroundImg: `${VIDEO_BASE_URL}products/sp1060ph/SP%20VIDEO.mp4`,
    },
  ],
  "orissa synchrodex solder module": [
    {
      backgroundImg: `${IMAGE_BASE_URL}products/sync-s/SYNC S DUAL BATH.jpg`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/sync-s/SYNC S ISO.png`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/sync-s/SYNC S Line.png`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/sync-s/SYNC S Open.png`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/sync-s/SYNC S RENDER FRONT VIEW.png`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/sync-s/SYNC S RENDER TOP VIEW.png`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/sync-s/SYNC S RENDER DOORS DOWN.png`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/sync-s/SYNC S RENDER DOORS UP.png`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/sync-s/SYNC S SINGLE BATH.jpg`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/sync-s/SYNC S Standard Bath.jpg`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/sync-s/SYNC S.png`,
    },
    {
      backgroundImg: `${VIDEO_BASE_URL}products/sync-s/SYNC S VIDEO (replace with China video).mp4`,
    },
  ],
  "orissa synchrodex pre-heat module": [
    {
      backgroundImg: `${IMAGE_BASE_URL}products/sync-ph/SYNC PH BOTTOM SIDE PRE-HEAT.jpg`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/sync-ph/SYNC PH ISO.png`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/sync-ph/SYNC PH LINE.png`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/sync-ph/SYNC PH RENDER DOORS DOWN.png`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/sync-ph/SYNC PH RENDER FRONT VIEW.png`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/sync-ph/SYNC PH RENDER TOP VIEW.png`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/sync-ph/SYNC PH.png`,
    },
  ],
  "synchrodex fluxer module": [
    {
      backgroundImg: `${IMAGE_BASE_URL}products/sync-f/SYNC F FLUX HEADS.jpg`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/sync-f/SYNC F ISO.png`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/sync-f/SYNC F RENDER DOORS DOWN.png`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/sync-f/SYNC F RENDER FRONT VIEW.png`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/sync-f/SYNC F RENDER TOP VIEW.png`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/sync-f/SYNC F.png`,
    },
    {
      backgroundImg: `${VIDEO_BASE_URL}products/sync-f/SYNC F VIDEO (replace with China video).mp4`,
    },
  ],
  "orissa synchrodex pro 1060mm solder module": [
    {
      backgroundImg: `${IMAGE_BASE_URL}products/sp1060-s/SP1060 S DOORS DOWN.png`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/sp1060-s/SP1060 S ISO.png`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/sp1060-s/SP1060 S RENDER FRONT VIEW.png`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/sp1060-s/SP1060 S RENDER TOP VIEW.png`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/sp1060-s/SP1060 S.jpg`,
    },
    {
      backgroundImg: `${VIDEO_BASE_URL}products/sp1060-s/SP ANIMATION.mp4`,
    },
    {
      backgroundImg: `${VIDEO_BASE_URL}products/sp1060-s/SP VIDEO.mp4`,
    },
  ],
  "orissa synchrodex pro 1060mm fluxer module": [
    {
      backgroundImg: `${IMAGE_BASE_URL}products/sp1060-f/SP1060 F ISO.png`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/sp1060-f/SP1060 F RENDER DOORS DOWN.png`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/sp1060-f/SP1060 F RENDER FRONT VIEW.png`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/sp1060-f/SP1060 F RENDER TOP VIEW.png`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/sp1060-f/SP1060 F.jpg`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/sp1060-f/SP1060 F.png`,
    },
    {
      backgroundImg: `${VIDEO_BASE_URL}products/sp1060-f/SP ANIMATION.mp4`,
    },
    {
      backgroundImg: `${VIDEO_BASE_URL}products/sp1060-f/SP VIDEO.mp4`,
    },
  ],
  "orissa synchrodex pro 1060mm pre-heater module": [
    {
      backgroundImg: `${IMAGE_BASE_URL}products/sp1060ph/SP1060 PH DOORS DOWN.png`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/sp1060ph/SP1060 PH ISO.png`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/sp1060ph/SP1060 PH RENDER FRONT VIEW.png`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/sp1060ph/SP1060 PH RENDER TOP VIEW.png`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/sp1060ph/SP1060 PH.png`,
    },
    {
      backgroundImg: `${VIDEO_BASE_URL}products/sp1060ph/SP ANIMATION.mp4`,
    },
    {
      backgroundImg: `${VIDEO_BASE_URL}products/sp1060ph/SP VIDEO.mp4`,
    },
  ],
  "jade mkii": [
    {
      backgroundImg: `${IMAGE_BASE_URL}products/jade-mkii/MKII FRONT RENDER.png`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/jade-mkii/MKII ISO.png`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/jade-mkii/MKII PCB Station.jpg`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/jade-mkii/MKII RENDER FRONT VIEW.png`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/jade-mkii/MKII RENDER TOP VIEW.png`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/jade-mkii/MKII Top Side Pre-Heat.jpg`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/jade-mkii/MKII.png`,
    },
    {
      backgroundImg: `${VIDEO_BASE_URL}products/jade-mkii/MKII VIDEO (replace with China video).mp4`,
    },
  ],
  "jade prodex": [
    {
      backgroundImg: `${IMAGE_BASE_URL}products/jpx/JPX Dual Solder Baths.jpg`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/jpx/JPX ISO.png`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/jpx/JPX RENDER FRONT VIEW.png`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/jpx/JPX RENDER PH DOWN.png`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/jpx/JPX RENDER TOP VIEW.png`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/jpx/JPX.png`,
    },
    {
      backgroundImg: `${VIDEO_BASE_URL}products/jpx/JPX VIDEO (replace with China video).mp4`,
    },
  ],
  "jade pro": [
    {
      backgroundImg: `${IMAGE_BASE_URL}products/jp/JP Dual Solder Baths.jpg`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/jp/JP ISO.png`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/jp/JP RENDER FRONT VIEW.png`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/jp/JP RENDER TOP VIEW.png`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/jp/JP RENDER.png`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/jp/JP Solder Trolley.jpg`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/jp/JP.jpg`,
    },
    {
      backgroundImg: `${VIDEO_BASE_URL}products/jp/JP VIDEO (replace with China video).mp4`,
    },
  ],
  "orissa fusion pro 508": [
    {
      backgroundImg: `${IMAGE_BASE_URL}products/orissa-fusion-pro-508/FPRO FRONT RENDER.png`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/orissa-fusion-pro-508/FPRO ISO CLEAR.png`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/orissa-fusion-pro-508/FPRO ISO.png`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/orissa-fusion-pro-508/FPRO SECT.png`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/orissa-fusion-pro-508/FPRO Solder Bath.jpg`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/orissa-fusion-pro-508/FPRO TOP VIEW.png`,
    },
    {
      backgroundImg: `${VIDEO_BASE_URL}products/orissa-fusion-pro-508/FPRO VIDEO (replace with animation).mp4`,
    },
  ],
  "orissa fusion 3 standard": [
    {
      backgroundImg: `${IMAGE_BASE_URL}products/f3s/F3S (replace – bad colouring, consistent angle, can’t read machine name).png`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/f3s/F3S Bottom Side Pre-Heat.jpg`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/f3s/F3S ISO.png`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/f3s/F3S RENDER DOORS DOWN.png`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/f3s/F3S RENDER FRONT VIEW.png`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/f3s/F3S RENDER TOP VIEW.png`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/f3s/F3S Solder Bath.jpg`,
    },
    {
      backgroundImg: `${VIDEO_BASE_URL}products/f3s/F3S VIDEO (replace with China video).mp4`,
    },
  ],
  "orissa fusion 3 compact": [
    {
      backgroundImg: `${IMAGE_BASE_URL}products/f3c/F3C (replace – bad coloring, consistent angle, can’t read machine name).jpg`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/f3c/F3C Bottom Side Pre-Heat.jpg`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/f3c/F3C ISO.png`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/f3c/F3C RENDER DOORS DOWN.png`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/f3c/F3C RENDER FRONT VIEW.png`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/f3c/F3C RENDER TOP VIEW.png`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/f3c/F3C Solder Bath.jpg`,
    },
    {
      backgroundImg: `${VIDEO_BASE_URL}products/f3c/F3C VIDEO (replace with China video).mp4`,
    },
  ],
};

export const BANNER_TXT_DETAILS = {
  default: {
    details: {
      Installations: "800+",
      "Worked Hours": "4.5k+",
      "Produced Boards": "2k+",
    },
    title: "Orissa Synchrodex Pro Combo",
  },
  aerospace: {
    details: {
      "Customer Base": "40 countries",
      "Max weight": "36kg+",
      "Max Pcb Size": "24” x 48” (610mm x 1200mm)",
    },
    titlePrefix: "Market Sector",
    title: "Electric Vehicles",
  },
  "automotive/ev": {
    details: {
      "Countries mfg automotive product with Pillarhouse systems": "18",
      "supplier to all major automotive manufacturers": "100%",
      "supplying automotive industry with soldering solutions": "1977-to today",
    },
  },
  medical: {
    details: {
      Installations: "40+",
      Effectiveness: "97%",
      "Market Share": "28%",
    },
  },
  product: {
    details: {
      Installations: "800+",
      "Worked Hours": "4.5k+",
      "Produced Boards": "2k+",
    },
    title: "Orissa Synchrodex Pro Combo",
  },
  "synchrodex pro 1600 dual bath solder module": {
    details: {
      "PCB Roller Conveyor Capacity": "36kg",
      "Max PCB Size": "1200mm x 610mm (45” x 24”)",
      "Parallel PCB Processing": "2x",
    },
  },
  "synchrodex pro 1600 single bath solder module": {
    details: {
      "Roller Conveyor CAP": "36kg",
      "Max PCB Size": "1157mm x 610mm (45” x 24”)",
      "Top/Bottom Power": "Max 32 Kw",
    },
  },
  "synchrodex pro 1600 pre-heater module": {
    details: {
      "Pre-Heat Power": "<=32Kw",
      "Pre-Heat Options": "Top & Bottom",
      "Max Ramp Rate": "<= 3 °C/sec",
    },
  },
  "orissa synchrodex pro 1060 pre-heater module": {
    details: {
      "Pre-Heat Power": "<=16Kw",
      "Pre-Heat Configurations": "Top & Bottom",
      "Closed-Loop Ramp Rate": "<= 3 °C/sec",
    },
  },
  "orissa fusion pro 508": {
    details: {
      "Pump Type": "Mech/Magnetic",
      "Solder Baths (Tandem)": "1 - 2",
      "PCB Size": " 0.5m x 0.5m (20” x 20”)",
    },
  },
  "orissa synchrodex solder module": {
    details: {
      Markets: "40+ Countries",
      "Total Modules": "1-12",
      "Install Base": "800+",
    },
  },
  "orissa synchrodex pre-heat module": {
    details: {
      "Pre-Heat Configurations": "Top & Bottom",
      "Pre-Heat PWR": "Up to 16KV",
      "Closed-Loop Ramp Rate": "Up to 3 °C/sec",
    },
  },
  "orissa fusion 4": {
    details: {
      "User Base Breakdown":
        "30% EMS, 20% Industrial, 15% Consumer, 15% Telecommunications + Medical, 10% Aerospace + Defense, 10% Other",
      "Number of Solder Baths": "<=4",
      "Bath Change Method": "Auto Cart Change x4",
    },
  },
  "synchrodex fluxer module": {
    details: {
      "Flux Heads": "<=2",
      "Fluxer Type": "Drop-Jet &amp; Ultrasonic",
      "Pre-Heat Config.": "Top &amp; Bottom *opt.",
    },
  },
  "orissa synchrodex pro 1060mm solder module": {
    details: {
      "Pre-Heat Power Top/Bottom": "Max. 16Kw",
      "Fluxer Type": "Drop Jet &amp; Ultrasonic",
      "Pump Type": "Magnetic or Mech.",
    },
  },
  "orissa synchrodex pro 1060mm fluxer module": {
    details: {
      "Top Speed": "250 mm/sec",
      "Fluxer Type": "Drop-Jet &amp; Ultrasonic",
      "Dual Fluxing Pitch": "80-250mm",
    },
  },
  "orissa synchrodex pro 1060mm pre-heater module": {
    details: {
      "Pre-Heat power": "<=16Kw",
      "Pre-Heat Configurations": "Top &amp; Bottom",
      "Closed-Loop Ramp Rate": "<=3 °C/s",
    },
  },
  "jade mkii": {
    details: {
      "Machine Installs": "1,400+",
      "Countries Served": "40+",
      "Compact Size": "1.35 m2",
    },
  },
  "jade prodex": {
    details: {
      "Speed of Trans.": "5 seconds/ PCB",
      ROI: "1-1.5 years CAPEX ROI",
      "# Solder Baths": "2",
    },
  },
  "jade pro": {
    details: {
      "Repeatability/Accuracy": "0.15mm",
      "Solder Bath Capacity": "2",
      "Pre-Heat Capability": "8Kw",
    },
  },
  "orissa fusion 3 standard": {
    details: {
      "Customer Base": "> 40 Countries",
      "# Solder Baths": "<=3",
      "Active Cells": "3",
    },
  },
  "orissa fusion 3 compact": {
    details: {
      "Customer Base": "> 40 Countries",
      "# Solder Baths": "<=3",
      "Active Cells": "3",
    },
  },
};
