import { useNavigate, useParams } from "react-router-dom";
import styles from "./FeatureItem.module.scss";
import { IconCurrentLocation, IconCurrentLocationGray } from "icons";

const currentLocationIcons = {
  red: <IconCurrentLocation />,
  gray: <IconCurrentLocationGray />,
};

export const FeatureItem = ({
  text,
  xPos,
  yPos,
  rightPos,
  pointFromRight = false,
  pointColor = "red",
  enabled,
  hasDashFromPoint,
  dashLength = "300px",
  dashTransform = "rotate(0deg)",
  width,
  forwardTo,
  standardFeature,
}) => {
  const { company } = useParams();
  const navigate = useNavigate();

  const handleClickItem = () => {
    if (forwardTo) {
      navigate(`/${company}/${forwardTo}`, { state: { from: 'FeatureItem' } });
    }
  };

  return (
    <div
      style={{ left: xPos, right: rightPos, top: yPos, width }}
      className={`${styles.container} ${pointFromRight && styles.leftPaddingOnly
        } ${!enabled && styles.disabled} ${forwardTo && styles.withForward} ${standardFeature ? styles.standardFeature : ""}`}
      onClick={handleClickItem}
    >
      {!pointFromRight && currentLocationIcons[pointColor]}
      <p className={styles.text}>{text}</p>
      {pointFromRight && currentLocationIcons[pointColor]}
      {hasDashFromPoint && (
        <div
          className={styles.dashLine}
          style={{
            transform: dashTransform,
            width: dashLength,
            position: "absolute",
            borderTop: "3px dashed #989898",
            left: pointFromRight ? "100%" : "",
            right: pointFromRight ? "" : "100%",
            top: "50%",
            transformOrigin: pointFromRight ? "left center" : "right center",
          }}
        />
      )}
    </div>
  );
};
