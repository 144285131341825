import { useEffect, useState } from "react";
import { useTranslation } from "hooks/useTranslation";
import { apiClient } from "services/api";
import { PrimaryDialog } from "../PrimaryDialog";
import { Button } from "../Button";
import { MainInputInput } from "../MainInput";
import { TextArea } from "../TextArea";
import { FileInput } from "../FileInput";
import { Select } from "../Select";
import styles from "./BrandCreationDialog.module.scss";
import { IconDarkClose } from "icons";
import { IconArrowLeft } from "icons";
import { CompanyCard } from "./components/CompanyCard";
import { useSnackbar } from "notistack";
import { getError } from "utils/errMessageUtils";
import { useDispatch, useSelector } from "react-redux";
import { updatePersistKey } from "reduxStore/slices/configuratorSlice";
import { setSelectedCompany } from "reduxStore/slices/companySlice";
import { fetchCompaniesListAsync } from "reduxStore/slices/companySlice";
import { productsListSelector } from "reduxStore/slices/selectors";

const brandCreationInitialForm = {
  logo: null,
  name: "",
  contact_person: "",
  contact_email: "",
  intro: "",
  launch_page: "",
};

const defaultOptions = [
  { value: "home", label: "Home page" },
  { value: "automotive/ev", label: "Market Sector > Automotive/EV" },
  { value: "aerospace", label: "Market Sector > Aerospace" },
  { value: "medical", label: "Market Sector > Medical" },
];

export const BrandCreationDialog = props => {
  const { translate } = useTranslation();
  const {
    isOpen,
    handleClose,
    dialogRef,
    selectRef,
    form,
    setForm,
    brandCreationDialog,
    savedClients,
  } = props;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSavedClientsMode, setIsSavedClientsMode] = useState(false);
  const [extendedOptions, setExtendedOptions] = useState(defaultOptions);
  const productsList = useSelector(productsListSelector);
  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setForm({ ...form, [name]: value });
  };

  const handleFileUpload = async logo => {
    try {
      setForm(prevForm => ({
        ...prevForm,
        logo,
      }));
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: "error" });
    }
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      const formData = new FormData();
      if (form.logo) {
        formData.append("logo", form.logo);
      }
      if (form.name) {
        formData.append("path", form.name?.replace(" ", "_"));
        formData.append("name", form.name);
      }
      if (form.contact_person) {
        formData.append("contact_person", form.contact_person);
      }
      if (form.contact_email) {
        formData.append("contact_email", form.contact_email);
      }
      if (form.intro) {
        formData.append("intro", form.intro);
      }
      if (form.launch_page) {
        formData.append("launch_page", form.launch_page);
      }

      const clientWithName = savedClients.find(
        c => c?.name?.trim() === form?.name?.trim()
      );

      if (clientWithName) {
        const { data } = await apiClient.put(
          `/company/${clientWithName?.id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        window.location.hash = `#/${data.path}`;
        dispatch(updatePersistKey());
      } else {
        const { data } = await apiClient.post("/company", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        setForm({
          ...form,
          launch_page: data.launch_page,
          path: data.path,
          logoUrl: data.logo_url,
        });
        window.location.hash = `#/${data.path}`;
        dispatch(updatePersistKey());
      }
      dispatch(fetchCompaniesListAsync());
      dispatch(setSelectedCompany(form));
      enqueueSnackbar(
        `Successfully ${clientWithName?.id ? "updated" : "created"}!`,
        {
          variant: "success",
        }
      );
      brandCreationDialog.closeDialog();
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: "error" });
    } finally {
      window.location.reload();
    }
    setIsSubmitting(false);
  };

  const handleSelectSavedClient = client => {
    setForm({
      id: client.id,
      logoUrl: client.logo_url,
      name: client.name,
      contact_person: client.contact_person,
      contact_email: client.contact_email,
      intro: client.intro,
      launch_page: client.launch_page,
    });
    setIsSavedClientsMode(false);
  };

  useEffect(() => {
    if (productsList && productsList.length > 0) {
      const productOptions = productsList.map(product => ({
        value: product.name,
        label: `Products > Machines > ${product.name}`,
      }));

      setExtendedOptions([...defaultOptions, ...productOptions]);
    }
  }, [productsList]);

  useEffect(() => {
    if (!isOpen) {
      setForm(brandCreationInitialForm);
    }
    return () => {
      setForm(brandCreationInitialForm);
    };
  }, [setForm, isOpen]);

  console.log("form", form);

  return (
    <PrimaryDialog
      className={styles.brandCreationDialog}
      blurBackdrop
      mode="dark"
      size="sm"
      radius="radius-20"
      open={isOpen}
    >
      <div ref={dialogRef} className={styles.contentContainer}>
        <div className={styles.header}>
          <div className={styles.leftBlock}>
            {isSavedClientsMode && (
              <div
                className={styles.arrowIconBlock}
                onClick={() => setIsSavedClientsMode(false)}
              >
                <IconArrowLeft />
              </div>
            )}
            <h2 className={styles.title}>
              {isSavedClientsMode
                ? translate("Saved Clients")
                : translate("Client Info")}
            </h2>
          </div>
          <div
            onClick={handleClose}
            className={`${styles.closeIconContainer} ${
              isSavedClientsMode && styles.active
            }`}
          >
            <IconDarkClose />
          </div>
        </div>
        {isSavedClientsMode ? (
          <div className={styles.savedClientsContainer}>
            <div className={styles.savedClientsList}>
              {savedClients.map(client => (
                <CompanyCard
                  onSelect={() => handleSelectSavedClient(client)}
                  key={client.id}
                  name={client.name}
                  logo={client.logo_url}
                />
              ))}
            </div>
          </div>
        ) : (
          <div className={styles.body}>
            <FileInput
              imgUrl={form.logoUrl}
              onFileUpload={handleFileUpload}
              placeholder={translate("Add Logo")}
            />
            <MainInputInput
              styleType="strongDark"
              name="name"
              placeholder="Company Name"
              type="text"
              value={form.name}
              onChange={handleChange}
              required
            />
            <MainInputInput
              styleType="strongDark"
              name="contact_person"
              placeholder="Contact Name"
              type="text"
              value={form.contact_person}
              onChange={handleChange}
              required
            />
            <MainInputInput
              styleType="strongDark"
              name="contact_email"
              placeholder="Email"
              type="text"
              value={form.contact_email}
              onChange={handleChange}
              required
            />
            <TextArea
              styleType="strongDark"
              name="intro"
              placeholder="Description"
              value={form.intro}
              onChange={handleChange}
              size="xl"
              required
            />
            <Select
              ref={selectRef}
              name="launch_page"
              options={extendedOptions}
              value={form.launch_page}
              onChange={handleChange}
              label={translate("Choose First Page")}
            />
          </div>
        )}
        {!isSavedClientsMode && (
          <div className={styles.footer}>
            {/* <Button
              onClick={() => setIsSavedClientsMode(true)}
              icon={<IconHistory />}
              type={"transparent"}
            >
              {translate("Saved Clients")}
            </Button> */}
            <Button
              disabled={isSubmitting}
              onClick={handleSubmit}
              className={styles.saveBtn}
            >
              {/* {form.id ? translate("Save and Open") : translate("Save")} */}
              {translate("Save")}
            </Button>
          </div>
        )}
      </div>
    </PrimaryDialog>
  );
};
